import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

import { getAppConfiguration, loadConfiguration } from 'config/config';

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const App = lazy(() => import('./App'));

const renderApp = () => {
  const appConfig = getAppConfiguration();
  if (appConfig.analytics.hubspotUrl) {
    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.type = 'text/javascript';
    script.src = appConfig.analytics.hubspotUrl;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<App />);
};

loadConfiguration(() => {
  renderApp();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
