import { AppConfig } from 'models/AppConfig';
import { buildConfig } from './buildConfig';

let config: AppConfig | null = null;

export function getAppConfiguration() {
  if (config == null) throw Error('Missing app configuration');

  return config;
}

export function setConfiguration(appConfig: AppConfig) {
  config = appConfig;
}

export async function loadConfiguration(onSuccess: (appConfig: AppConfig) => void, origin = '') {
  const result = await fetch(`${origin}/appConfig.json`);
  const jsonValue = await result.json();

  setConfiguration(buildConfig(jsonValue));

  onSuccess(getAppConfiguration());
}
